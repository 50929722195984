import React, { useEffect, useState } from "react";
import "./QuikhireCountShow.css";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import CommonTable from "../../Components/table/CommonTable";
import { Url } from "../../EnvoirnmentSetup/APIs";
import { getAPICall } from "../../APIMethods/APIMethods";
import { useSelectedCardContext } from "../../Context/Context";
import quikhireCountShow from "../../assets/teamgementheader.svg";
import closePopup from "../../assets/closepopup.svg";
import noDataImg from "../../assets/nodataavailable.svg";
import moment from "moment";
import { ExcelDownload } from "../../constants/constantFunctions";

const QuikHireCountShow = () => {
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedJobsTableData, setSelectedJobsTableData] = useState([]);
  const [selectedJobsRowData, setSelectedJobsRowData] = useState();
  const [selectedJobsRowNote, setSelectedJobsRowNote] = useState();
  const [popupBothSelectedJobs, setPopupBothSelectedJobs] = useState();
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  const tableColumns = [
    {
      Header: "S/N",
      accessor: "srno",
    },
    {
      Header: "Student Name",
      accessor: "studentName",
      Cell: ({ row }) => (
        <div>
          <button
            className="qhcs_button"
            style={{ color: 'blue' }}
            onClick={() => handleClickStudentName(row.original.allRowData)}
          >
            {row?.original?.studentName}
          </button>
        </div>
      ),
    },
    {
      Header: "Campus Jobs (Notified)",
      accessor: "campusJobs",
      Cell: ({ row }) => (
        <div>
          <button
            className={row?.original?.campusJobs && "qhcs_button"}
            style={{ color: row?.original?.campusJobs > 0 && 'blue', cursor: row?.original?.campusJobs > 0 ? 'pointer' : 'auto' }}
            onClick={() => row?.original?.campusJobs > 0 && handleExcelDownload('notificationsReceived', 'Campus Jobs (Notified)', 'campusJobs', row?.original?.allRowData)}
          >
            {row?.original?.campusJobs}
          </button>
        </div>
      ),
    },
    {
      Header: "QuikHire Jobs (Visited)",
      accessor: "viewedJobs",
      Cell: ({ row }) => (
        <div>
          <button
            className={row?.original?.viewedJobs && "qhcs_button"}
            style={{ color: row?.original?.viewedJobs > 0 && 'blue', cursor: row?.original?.viewedJobs > 0 ? 'pointer' : 'auto' }}
            onClick={() => row?.original?.viewedJobs > 0 && handleExcelDownload('visits', 'QuikHire Jobs (Visited)', 'qhJobs', row?.original?.allRowData)}
          >
            {row?.original?.viewedJobs}
          </button>
        </div>
      ),
    },
    {
      Header: "Offered By",
      accessor: "jobsOffered",
      Cell: ({ row }) => (
        <div
          // style={{ maxHeight: '50px', overflowY: 'auto' }}
        >
          {row?.original?.jobsOffered?.split('#nextline').map((item, index) => (
            <div key={index + 1}>{item}</div>
          ))}
        </div>
      ),
    },
  ]

  const selectedJobsTableColumns = [
    {
      Header: "S/N",
      accessor: "srno",
    },
    {
      Header: "Job Title",
      accessor: "designationName",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Experience",
      accessor: "experience",
    },
  ]

  const campusCards = [
    {
      label: 'Notifications Received',
      key: 'notificationsReceived',
      count: selectedJobsRowData?.notifications
    },
    {
      label: 'Jobs Viewed',
      key: 'jobsViewed',
      count: selectedJobsRowData?.viewedJobs
    },
    {
      label: 'Jobs Applied',
      key: 'jobsApplied',
      count: selectedJobsRowData?.appliedJobs
    },
    {
      label: 'Shortlisted For Interview',
      key: 'interviewShortlisted',
      count: selectedJobsRowData?.interviewShortlisted
    },
    {
      label: 'Offered',
      key: 'offered',
      count: selectedJobsRowData?.shortlistedJobs
    },
    {
      label: 'Declined',
      key: 'declined',
      count: selectedJobsRowData?.rejectedJobs
    },
  ]

  const qhCards = [
    {
      label: 'Internships',
      key: 'internships',
      count: selectedJobsRowNote?.appliedInternJobsCount
    },
    {
      label: 'Freshers',
      key: 'freshers',
      count: selectedJobsRowNote?.appliedFresherJobsCount
    },
    {
      label: 'Offered',
      key: 'offered',
      count: selectedJobsRowNote?.selectedJobsCount
    },
  ]

  const { setIsLoading } = useSelectedCardContext();

  // -------------------------------------------------- API Integration ---------------------------------------------

  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      const tableData = await getAPICall(Url?.quikhireJobCount);
      if (tableData.SUCCESS) {
        setIsLoading(false);
        const newArr = tableData?.DATA?./* filter(item =>
          item?.performaceCounts?.viewedInternJobs !== 0 || item?.performaceCounts?.viewedFresherJobs !== 0
        )?. */map((item, i) => {
          const offeredJobList = []
          item?.performaceCounts?.selectedJobsCampus?.forEach(job => offeredJobList?.push(job?.job?.companyName + " (Campus)"))
          item?.performaceCounts?.selectedJobs?.forEach(job => offeredJobList?.push(job?.company?.companyName + " (QuikHire)"))
          return {
            srno: i + 1,
            studentName: item?.appUser?.fullName,
            campusJobs: item?.performaceCounts?.notifications || 0,
            viewedJobs: item?.performaceCounts?.viewedJobs,
            jobsOffered: offeredJobList?.join(",#nextline") || "-",
            allRowData: item
          };
        });

        setTableData(newArr);
        // setTableData(prev => [...prev, ...newArr]); // only for testing
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const closeStudentNameModal = () => {
    setOpenModal(false)
  }

  const handleClickStudentName = async (row) => {
    const apiUrl = Url.getStudentByMobileAndCountryCode.replace("{mobile}", row?.appUser?.mobileNumber).replace("{countryCodeId}", row?.appUser?.countryCode?.countryCodeId)
    // const apiUrl = Url.getStudentByMobileAndCountryCode.replace("{mobile}", 7038541867).replace("{countryCodeId}", 249)

    // console.log("currently clicked row", row)

    setOpenModal(true)
    try {
      setIsLoading(true);
      const studentTableData = await getAPICall(apiUrl);
      console.log("studentTableData", studentTableData)
      if (studentTableData.SUCCESS) {
        setIsLoading(false);
        setSelectedJobsRowData(studentTableData?.DATA)
        setSelectedJobsRowNote(studentTableData?.NOTE)
        let bothJobsList = []
        studentTableData?.DATA?.selectedJobStatus?.slice(1)?.forEach((item, i) => {
          bothJobsList.push({
            designationName: item?.job?.designation[0]?.designationName,
            companyName: item?.job?.companyName,
            experience: item?.job?.experience?.experience,
            selectedDate: item?.selectedDate,
            platform: 'Campus',
          });
        });
        studentTableData?.NOTE?.selectedJobStatus?.slice(1)?.forEach((item, i) => {
          bothJobsList.push({
            designationName: item?.job?.designation[0]?.designationName,
            companyName: item?.job?.company?.companyName,
            experience: item?.job?.experience?.experience,
            selectedDate: item?.selectedDate,
            platform: 'QuikHire',
          });
        });
        studentTableData?.DATA?.selectedJobStatus?.slice(0, 1)?.forEach((item, i) => {
          bothJobsList.push({
            designationName: item?.job?.designation[0]?.designationName,
            companyName: item?.job?.companyName,
            experience: item?.job?.experience?.experience,
            selectedDate: item?.selectedDate,
            platform: 'Campus',
          });
        });
        studentTableData?.NOTE?.selectedJobStatus?.slice(0, 1)?.forEach((item, i) => {
          bothJobsList.push({
            designationName: item?.job?.designation[0]?.designationName,
            companyName: item?.job?.company?.companyName,
            experience: item?.job?.experience?.experience,
            selectedDate: item?.selectedDate,
            platform: 'QuikHire',
          });
        });
        setPopupBothSelectedJobs(bothJobsList)
        const filteredData = studentTableData?.NOTE?.selected?.map((item, i) => {
          return {
            srno: i + 1,
            designationName: item?.designation[0]?.designationName,
            companyName: item?.company?.companyName,
            experience: item?.experience?.experience,
            allRowData: item
          };
        });
        setSelectedJobsTableData(filteredData);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  const handleExcelDownload = (key, fileName, jobType, rowData) => {
    let apiUrl = ''
    apiUrl = jobType == "campusJobs"
      ? Url?.downloadExcelOfSelectedJobReportCampus
        ?.replace('{key}', key)
        ?.replace("{userId}", rowData ? rowData?.appUser?.userId : selectedJobsRowData?.appUser?.userId)
      : Url?.downloadExcelOfSelectedJobReportQuikHire
        ?.replace('{key}', key)
        ?.replace("{mobile}", rowData ? rowData?.appUser?.mobileNumber : selectedJobsRowNote?.appUser?.mobileNumber)
        ?.replace("{country}", rowData ? rowData?.appUser?.countryCode?.countryCodeId : selectedJobsRowNote?.appUser?.countryCode?.countryCodeId)

    // console.log("handleExcelDownload fileName", fileName, "selectedJobsRowData", selectedJobsRowData, "apiUrl", apiUrl,"selectedJobsRowNote",selectedJobsRowNote)
    ExcelDownload(apiUrl, fileName)
  }

  return (
    <div className="mainquikhireCountShowContainer">
      <div className="quikhireCountShowBreadCrumbDiv">
        <div className="icon">
          <img src={quikhireCountShow} alt="teamicon" />
        </div>
        <ComponentAndScreenConstant.Breadcrumbs
          currentPage={["Placement Records"]}
        />
      </div>

      <div className="quikhireCountShowtableDiv">
        <CommonTable
          data={tableData}
          columns={tableColumns}
        />
      </div>

      <ComponentAndScreenConstant.CommonModal
        isOpen={openModal}
        onRequestClose={closeStudentNameModal}
        className="qhcs_modal"
      >
        <div className="qhcs_modalContainer" >
          <div className="closeicon">
            <button
              type="button"
              className="closepopupbtn"
              onClick={closeStudentNameModal}
            >
              <img src={closePopup} alt="closeicon" />
            </button>
          </div>
          <div className="qhcs_modalHeading"> Student Placement Record </div>
          <div className="qhcs_modalContents">
            {/* <div className=" qhsc_mb5 qhcs_contentgap">
              <span className="qhcs_labelBold">Student Name: </span>
              <span className="qhsc_value">{selectedJobsRowData?.appUser?.fullName}</span>
            </div> */}
            <div className=" qhsc_inline qhsc_mb5 qhcs_contentgap phemail">
              {/* <div className="qhcs_contentgap qhcs_subDivLarge">
                <div className="qhcs_labelBold">Name: </div>
                <div className="qhsc_value qhsc_email">{selectedJobsRowData?.appUser?.fullName}</div>
              </div>
              <div className="qhcs_contentgap qhcs_subDivSmall">
                <div className="qhcs_labelBold">Phone: </div>
                <div className="qhsc_value">{selectedJobsRowData?.appUser?.mobileNumber ? "+" + selectedJobsRowData?.appUser?.countryCode?.countryCode + " " + selectedJobsRowData?.appUser?.mobileNumber : ''}</div>
              </div>
              <div className="qhcs_contentgap qhcs_subDivLarge">
                <div className="qhcs_labelBold">Email: </div>
                <div className="qhsc_value qhsc_email">
                  <a href={`mailto:${selectedJobsRowData?.appUser?.userName}`} target="_blank">
                    {selectedJobsRowData?.appUser?.userName}
                  </a>
                </div>
              </div> */}
              <div className="qhcs_nameDiv qhcs_contentgap">
                <div className=""><div className="qhcs_labelBold">Name: </div>{selectedJobsRowData?.appUser?.fullName}</div>
                <div className="qhsc_icons">
                  <a target="_black" href={selectedJobsRowData?.appUser?.linkedinProfile}>
                    <img
                      src={
                        selectedJobsRowData?.appUser?.linkedinProfile?.includes('shareresume')
                          ? require('../../assets/favicon.png')
                          : require("../../assets/linkedInIcon.png")
                      }
                      alt=""
                      title='View Profile'
                      className={selectedJobsRowData?.appUser?.linkedinProfile?.includes('shareresume') ? "qhsc_icon1" : "qhsc_icon"}
                    />
                  </a>
                  <a href={`mailto:${selectedJobsRowData?.appUser?.userName}`} target="_blank">
                    <img
                      src={require("../../assets/mailIcon.png")}
                      alt=""
                      title='Send Email'
                      className="qhsc_icon"
                    />
                  </a>
                  <a href={`https://wa.me/${selectedJobsRowData?.appUser?.countryCode?.countryCode + "" + selectedJobsRowData?.appUser?.mobileNumber}`} target="_blank">
                    <img
                      src={require("../../assets/messageIcon.png")}
                      alt=""
                      title='WhatsApp Chat'
                      className="qhsc_icon"
                    />
                  </a>
                  {/* <a href={`tel:${selectedJobsRowData?.appUser?.countryCode?.countryCode} ${selectedJobsRowData?.appUser?.mobileNumber}}`} target="_blank"> */}
                  <img
                    onClick={() => setShowPhoneNumber(!showPhoneNumber)}
                    src={require("../../assets/call_Icon.png")}
                    alt=""
                    title={`Contact${selectedJobsRowData?.appUser?.mobileNumber ? `: +${selectedJobsRowData?.appUser?.countryCode?.countryCode} ${selectedJobsRowData?.appUser?.mobileNumber}` : ''}`}
                    className="qhsc_icon"
                  />
                  {/* </a> */}
                </div>
                {showPhoneNumber && <span className="qhcs_showPhoneNumber">
                  {selectedJobsRowData?.appUser?.mobileNumber ? ("+" + selectedJobsRowData?.appUser?.countryCode?.countryCode + " " + selectedJobsRowData?.appUser?.mobileNumber) : ''}
                </span>}
              </div>
            </div>
            {popupBothSelectedJobs?.length > 0 &&
              <div className="qhsc_mb5 qhcs_contentgap">
                <div className="qhsc_value qhsc_resumelink">
                  {
                    popupBothSelectedJobs?.length > 0
                      ? popupBothSelectedJobs?.slice(0, 2)?.map(job => (
                        <div className="qhsc_value qhsc_iconMedalDiv">
                          <img
                            src={require("../../assets/achievement.png")}
                            alt="achievement"
                            className="qhsc_iconMedal"
                          />
                          Offered by <span style={{ color: '#01774a' }}>{job?.companyName}</span> on {moment(job?.selectedDate).format("DD-MMM-YYYY")} for the <span style={{ color: '#01774a' }}>{job?.designationName}</span> posted in {job?.platform}
                        </div>
                      ))
                      : ''
                  }
                </div>
              </div>
            }
            <div className="qhsc_mb5 qhcs_contentgap">
              <span className="qhcs_labelBold">Keywords: </span>
              <div className="qhsc_value qhsc_resumelink">
                {selectedJobsRowData?.appUser?.skill.length > 0
                  ? selectedJobsRowData?.appUser?.skill?.map(skill => skill?.name)?.join(", ")
                  : '-'}
              </div>
            </div>
            {/* <div className="qhsc_mb5 qhcs_contentgap">
              <span className="qhcs_labelBold">Profile Url: </span>
              <div className="qhsc_value qhsc_resumelink">
                <a target="_black" href={selectedJobsRowData?.appUser?.linkedinProfile}>
                  {selectedJobsRowData?.appUser?.linkedinProfile}
                </a>
              </div>
            </div> */}
            <div className="qhcs_content qhsc_mb5 qhcs_contentgap">
              <div className="qhcs_labelClippath polygon">Campus Jobs:</div>
              <div className="qhcs_labelCards">
                <div className="qhsc_value qhsc_inline qhsc_mb5 countwrapper">
                  {campusCards.map(card =>
                    <div
                      className="qhsc_countCard"
                      style={{ cursor: card?.count > 0 ? 'pointer' : 'not-allowed' }}
                      onClick={() => card?.count > 0 && handleExcelDownload(card?.key, card?.label, 'campusJobs')}
                    >
                      <div className="qhsc_countLabel">
                        {card?.label}
                      </div>
                      <div className="qhsc_countCircle">{card?.count ? card?.count : '0'}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="qhcs_content qhsc_mb5 qhcs_contentgap">
              <div className="qhcs_labelClippath polygon">QuikHire Jobs: </div>
              <div className="qhcs_labelCards">
                <div className="qhsc_value qhsc_inline qhsc_mb5 countwrapper">
                  {qhCards.map(card =>
                    <div
                      className="qhsc_countCard"
                      style={{ cursor: card?.count > 0 ? 'pointer' : 'not-allowed' }}
                      onClick={() => card?.count > 0 && handleExcelDownload(card?.key, card?.label, 'qhJobs')}
                    >
                      <div className="qhsc_countLabel">
                        {card?.label}
                      </div>
                      <div className="qhsc_countCircle">{card?.count ? card?.count : '0'}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className=" qhsc_mb5">
              <span className="qhcs_labelBold">Job Offers Received: </span>
              {
                selectedJobsTableData?.length > 0 ?
                  <div className="qhsc_value qhsc_selectedJobsTable">
                    <CommonTable
                      data={selectedJobsTableData}
                      columns={selectedJobsTableColumns}
                      // data={tableData}
                      // columns={tableColumns}
                      showTableActions={false}
                    />
                  </div>
                  :
                  <div className="qhsc_nodata">
                    <div className="imgcontainer">
                      <img src={noDataImg} alt="" />
                      <div className="message">
                        <span cl>No Data Available.</span>
                      </div>
                    </div>
                  </div>
              }
            </div> */}
          </div>
        </div>
      </ComponentAndScreenConstant.CommonModal>
    </div>
  )
}

export default QuikHireCountShow;
