import React, { useEffect, useState } from "react";
import "./Login.css";
import logoImageUrl from "../../assets/quikHireLogo.png";
import { field } from "../../Validation/Validation";
import { LoginAutonavigateEnv, PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
import Modal from "react-modal";
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import completed from "../../assets/Success.json";
import { useNavigate } from "react-router-dom";
import mobileImg from "../../assets/mobileImg.svg";
import OtpInput from "react-otp-input";
import eyeIcon from "../../assets/eyeIcon.svg";
import hiddenEyeIcon from "../../assets/hiddenEyeIcon.svg";
import { SHA256 } from "crypto-js";
import { getBaseURLTonavigatetoLogin } from "../../EnvoirnmentSetup/profiles";
import { getAPICall, patchAPICall, postAPICall } from "../../APIMethods/APIMethods";
// import { getBaseURLForDevLogin, getBaseURLTonavigatetoLogin } from "../../EnvoirnmentSetup/profiles";

const Login = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [OTPAuditID, setOTPAuditID] = useState("");
  const [mobileNumber, setmobileNumber] = useState(field);
  const [resetOtp, setresetOtp] = useState(false);
  const [reason, setreason] = useState(field);
  const [isotpSent, setisotpSent] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const [error, setError] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [hidePwd, setHidePwd] = useState({
    pwd: false,
    npwd: false,
    cnpwd: false,
  });
  const [forgotPassFlow, setForgotPassFlow] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({
    countryCodeId: 249,
    countryName: "United States",
    countryCode: "1",
    currency: "$",
    currencyPosition: false,
    countryFlag: "US",
  });
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [SuccessModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [popupMsg, setPopupMsg] = useState("");
  const [forgotPwd, setForgotPwd] = useState(false);
  const [modal, setModal] = useState(false);
  var mnoRegex = /^\d+$/;
  var bothUpperLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
  var lowerCase = /.*[a-z].*/;
  var upperCase = /.*[A-Z].*/;
  var oneDigitRegex = /.*[0-9].*/;
  var oneSpclChar = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~`]).+$/;
  const [lgnDetails, setLgnDetails] = useState({
    cntryCode: "249",
    mno: "",
    pwd: "",
    npwd: "",
    cnpwd: "",
  });
  const [screen, setScreen] = useState(1);
  const [errMsg, setErrMsg] = useState({
    cntryCode: "",
    mno: "",
    pwd: "",
    npwd: "",
    cnpwd: "",
  });
  const [otp2, setOtp2] = useState("");
  const [msgAuditId, setMsgAuditId] = useState();

  useEffect(() => {
    const getUserData = () => {
      let token = sessionStorage.getItem("token");
      if (!token && !sessionStorage.getItem('jobId')) {
        sessionStorage.clear()
      }
      if (token?.length > 0) {
        navigate("/Main/latestJob");
      } else {
        let fullURL = window.location.href;
        fullURL = fullURL.replace("#", "");
        const url = new URL(fullURL);
        let userParamsCCId = !!url.searchParams.get("CCId")
          ? url.searchParams.get("CCId")
          : "";
        let userParamsmobNumber = !!url.searchParams.get("mobilenumber")
          ? url.searchParams.get("mobilenumber")
          : "";
        if (!!userParamsCCId && !!userParamsmobNumber) {
          setLgnDetails({
            cntryCode: userParamsCCId,
            mno: userParamsmobNumber,
            pwd: "",
            npwd: "",
            cnpwd: "",
          });
          setScreen(1);
        } else {
          navigate("/login");
        }
      }
    };
    getUserData();
  }, []);
  useEffect(() => {
    if (otp2?.length > 3) {
      handleSetPwd()
    }
  }, [otp2])
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
    overlay: {
      zIndex: 1
    },
  };

  useEffect(() => {
    getAPICall(Url.CountryCodes)
      .then((res) => {
        setCountryCodes(res?.DATA);
      }).catch((e) => console.log(e));
  }, []);

  const handleLgnInputChange = (e, field) => {
    switch (field) {
      case "cntryCode":
        setLgnDetails({ ...lgnDetails, cntryCode: e?.target?.value });
        break;
      case "mno":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, mno: "Mobile number is required." });
        } else if (!mnoRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, mno: "Should contain only numbers" });
        } else if (e?.target?.value.length < 4) {
          setErrMsg({ ...errMsg, mno: "Minimum length should be 4" });
        }
        // else if (e?.target?.value.length > 10) {
        //   setErrMsg({ ...errMsg, mno: "Maximum length should be 10" })
        // }
        else if (!mnoRegex.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            mno: "Special characters and alphabets are not allowed.",
          });
        } else {
          setErrMsg({ ...errMsg, mno: "" });
        }
        if (e?.target?.value.length <= 10) {
          setLgnDetails({ ...lgnDetails, mno: e?.target?.value });
        }
        break;
      case "pwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, pwd: "Password is required." });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one upper case letter.",
          });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one lower case letter.",
          });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, pwd: "Should accept atleast one digit." });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            pwd: "Should accept atleast one special character.",
          });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, pwd: "Minimum length should be 8." });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, pwd: "Maximum length should be 16." });
        } else {
          setErrMsg({ ...errMsg, pwd: "" });
        }
        setLgnDetails({ ...lgnDetails, pwd: e?.target?.value });
        break;
      case "npwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, npwd: "Password is required." });
        } else if (!upperCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one upper case letter.",
          });
        } else if (!lowerCase.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one lower case letter.",
          });
        } else if (!oneDigitRegex.test(e?.target?.value)) {
          setErrMsg({ ...errMsg, npwd: "Should accept atleast one digit." });
        } else if (!oneSpclChar.test(e?.target?.value)) {
          setErrMsg({
            ...errMsg,
            npwd: "Should accept atleast one special character.",
          });
        } else if (e?.target?.value.length < 8) {
          setErrMsg({ ...errMsg, npwd: "Minimum length should be 8." });
        } else if (e?.target?.value.length > 16) {
          setErrMsg({ ...errMsg, npwd: "Maximum length should be 16." });
        } else {
          setErrMsg({ ...errMsg, npwd: "" });
        }
        setLgnDetails({ ...lgnDetails, npwd: e?.target?.value });
        break;
      case "cnpwd":
        if (e?.target?.value == "") {
          setErrMsg({ ...errMsg, cnpwd: "Confirm Password is required." });
        } else if (lgnDetails?.npwd != e?.target?.value) {
          setErrMsg({ ...errMsg, cnpwd: "Password doesn't match." });
        } else {
          setErrMsg({ ...errMsg, cnpwd: "" });
        }
        setLgnDetails({ ...lgnDetails, cnpwd: e?.target?.value });
        break;
      default:
        setErrMsg({ cntryCode: "", mno: "", pwd: "", npwd: "", cnpwd: "" });
        break;
    }
  };
  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  function openSuccessModal() {
    setSuccessModalIsOpen(true);
  }

  function closeSuccessModal() {
    setSuccessModalIsOpen(false);
  }
  const handleSendOTP = async () => {
    if ((mobileNumber?.fieldValue.trim()).length > 0) {
      let OTPDetails = {
        countryCodeId: selectedCountry?.countryCodeId,
        toNumber: mobileNumber?.fieldValue,
        messageType: "OTP_SMS",
      };
      postAPICall(Url?.SendOtp, OTPDetails)
        .then((res) => {
          if (res?.SUCCESS) {
            setisotpSent(true);
            setOTPAuditID(res?.DATA);
            setsuccessMsg(
              `The OTP has been sent on ${selectedCountry?.countryCode} ${mobileNumber?.fieldValue}`
            );
            openSuccessModal();
          } else {
            setError("You are not a registered QuikHire user.");
            openFailModal();
          }
        });
    } else {
      setError(`Please enter mobile number.`);
      openFailModal();
    }
  };

  const handleReSendOTP = async () => {
    if ((mobileNumber?.fieldValue.trim()).length > 0) {
      setisotpSent(true);
      let OTPDetails = {
        countryCodeId: selectedCountry?.countryCodeId,
        toNumber: mobileNumber?.fieldValue,
        messageAuditId: OTPAuditID,
      };
      postAPICall(Url?.SendOtp, OTPDetails)
        .then((res) => {
          if (res?.SUCCESS) {
            setOTPAuditID(res?.DATA);
            setsuccessMsg(
              `The OTP has been resent on ${selectedCountry?.countryCode} ${mobileNumber?.fieldValue}`
            );
            openSuccessModal();
          } else {
            setError("You are not a registered QuikHire user.");
            openFailModal();
          }
        });
    } else {
      setError(`Please enter mobile number.`);
      openFailModal();
    }
  };

  const ValidateField = () => {
    if (
      mobileNumber?.fieldValue === "" ||
      mobileNumber?.isValidField === false
    ) {
      setError("A Mobile number is required.");
      openFailModal();
      return false;
    } else if (selectedCountry?.countryCodeId == "") {
      setError("Please select country code.");
      openFailModal();
      return false;
      /*    } else if (!isotpSent) {
           setError("Click 'Send OTP' and enter the received OTP.");
           openFailModal();
           return false;
         } else if (otp?.length != 4) {
           setError("Please enter OTP.");
           openFailModal();
           return false; */
    }
    return true;
  };

  const handleLogin = async () => {
    if (ValidateField()) {
      let userDetails = {
        countryCodeId: selectedCountry?.countryCodeId,
        username: mobileNumber?.fieldValue,
        /*auditId: OTPAuditID,
        otp: otp, */
        auditId: 0,
        otp: 1111,
      };
      postAPICall(Url.UserLogin, userDetails)
        .then((res) => {
          if (res?.SUCCESS) {
            sessionStorage.setItem("token", res?.token);
            sessionStorage.setItem("role", res?.roles);
            navigate("/Main/latestJob");
          } else {
            let originalString = res?.message;
            if (originalString.includes(':')) {
              let resultString = originalString
                .split(":")
                .slice(1)
                .join(":")
                .trim();
              setError(resultString);
            } else {
              setError(originalString);
            }
            openFailModal();
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const handleMobileNoVerification = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length of mobile number should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length of mobile number should be 10.");
      setModal(true);
    } else if (!mnoRegex.test(lgnDetails?.mno)) {
      setErrMsg({
        ...errMsg,
        mno: "Special characters and alphabets are not allowed.",
      });
    } else {
      try {
        getAPICall(Url.mobileNoVerification.replace("{countrycodeId}", lgnDetails?.cntryCode).replace("{mobile_number}", lgnDetails?.mno))
          .then((res) => {
            if (res?.SUCCESS == true && res?.DATA == true) {
              setScreen(2);
            } else if (res?.SUCCESS == true && res?.DATA == false) {
              setScreen(3);
              setForgotPassFlow(false)
            }
            // else if (res?.SUCCESS == false && (res?.DATA == "ROLE_RECRUTER" || res?.DATA == "ROLE_MANAGER" || res?.DATA == "ROLE_ADMIN")) {
            //   setPopupMsg("Only applicants can access this web application.")
            //   setModal(true)
            // }
            else if (res?.SUCCESS == false && res?.DATA && (PortalUserRole != res?.DATA)) {
              const result = getBaseURLTonavigatetoLogin(LoginAutonavigateEnv, res?.DATA) 
              if(result){
                window.open(result + "#/login?CCId=" + lgnDetails?.cntryCode + "&mobilenumber=" + lgnDetails?.mno, "_self")
              }
            } else {
              setPopupMsg(res?.message);
              setModal(true);
            }
          }).catch((e) => {
            console.log(e);
            setPopupMsg("Something went wrong.");
            setModal(true);
          });
      } catch (e) {
        console.log(e);
        setPopupMsg("Something went wrong.");
        setModal(true);
      }
    }
  };

  const handlePosterLogin = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length should be 10.");
      setModal(true);
    } else if (lgnDetails?.pwd == "") {
      setPopupMsg("Password is required.");
      setModal(true);
    } else if (!upperCase.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain at least one upper case letter.");
      setModal(true);
    } else if (!lowerCase.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain at least one lower case letter.");
      setModal(true);
    } else if (!oneDigitRegex.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain at least one digit.");
      setModal(true);
    } else if (!oneSpclChar.test(lgnDetails?.pwd)) {
      setPopupMsg("Password should contain at least one special character.");
      setModal(true);
    } else if (lgnDetails?.pwd.length < 8) {
      setPopupMsg("Minimum length of Password should be 8.");
      setModal(true);
    } else if (lgnDetails?.pwd.length > 16) {
      setPopupMsg("Maximum length of Password should be 16.");
      setModal(true);
    } else {
      try {
        let obj = {
          countryCodeId: lgnDetails?.cntryCode,
          username: lgnDetails?.mno,
          password: SHA256(lgnDetails?.pwd).toString(),
          role: PortalUserRole.replace('ROLE_',''),
        };
        postAPICall(Url?.UserLogin, obj)
          .then((ress) => {
            if (ress?.SUCCESS == true) {
              setLgnDetails({
                cntryCode: "249",
                mno: "",
                pwd: "",
                npwd: "",
                cnpwd: "",
              });
              let token = ress?.token;
              sessionStorage.setItem("token", token);
              getUserDataname();
            } else {
              setPopupMsg(ress?.message);
              setModal(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleOtpSend = () => {
    if (lgnDetails?.mno == "") {
      setPopupMsg("Mobile No. is required.");
      setModal(true);
    } else if (lgnDetails?.mno.length < 4) {
      setPopupMsg("Minimum length of mobile number should be 4.");
      setModal(true);
    } else if (lgnDetails?.mno.length > 10) {
      setPopupMsg("Maximum length of mobile number should be 10.");
      setModal(true);
    } else if (lgnDetails?.npwd == "") {
      setPopupMsg("New Password is required.");
      setModal(true);
    } else if (!upperCase.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain at least one upper case letter.");
      setModal(true);
    } else if (!lowerCase.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain at least one lower case letter.");
      setModal(true);
    } else if (!oneDigitRegex.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain at least one digit.");
      setModal(true);
    } else if (!oneSpclChar.test(lgnDetails?.npwd)) {
      setPopupMsg("Password should contain at least one special character.");
      setModal(true);
    } else if (lgnDetails?.npwd.length < 8) {
      setPopupMsg("Minimum length of Password should be 8.");
      setModal(true);
    } else if (lgnDetails?.npwd.length > 16) {
      setPopupMsg("Maximum length of Password should be 16.");
      setModal(true);
    } else if (lgnDetails?.cnpwd == "") {
      setPopupMsg("Confirm password is required!");
      setModal(true);
    } else if (lgnDetails?.cnpwd != lgnDetails?.npwd) {
      setPopupMsg("Password doesn't match.");
      setModal(true);
    } else {
      let obj = {
        web: true,
        countryCodeId: lgnDetails?.cntryCode,
        toNumber: lgnDetails?.mno,
        messageType: "OTP_SMS",
        registerd: true,
      };
      try {
        postAPICall(Url.SendOtp, obj)
          .then((res) => {
            if (forgotPassFlow == true) {
              setForgotPwd(true)
            } else {
              setForgotPwd(false)
            }
            if (res?.SUCCESS == true) {
              setScreen(4);
              setMsgAuditId(res?.DATA);
            } else {
              setPopupMsg(res?.message);
              setModal(true);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSetPwd = () => {
    try {
      if (otp2 == "") {
        setPopupMsg("Please enter OTP.");
        setModal(true);
      } else if (otp2?.length < 4) {
        setPopupMsg("Invalid OTP.");
        setModal(true);
      } else {
        let obj = {
          messageAuditId: msgAuditId,
          messageOTP: otp2,
          countryCode: {
            countryCodeId: lgnDetails?.cntryCode,
          },
          mobileNumber: lgnDetails?.mno,
          password: SHA256(lgnDetails?.npwd).toString(),
        };
        patchAPICall(Url.forgotPwd, obj)
          .then((res) => {
            if (res?.SUCCESS == true) {
              setPopupMsg("Password Set Successfully!");
              setSuccessPopup(true);
              // setLgnDetails({ cntryCode: "249", mno: "", pwd: "", npwd: "", cnpwd: "" })
            } else {
              setPopupMsg(res?.message);
              setModal(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleForgotPwd = () => {
    setForgotPwd(true);
    setScreen(3);
    setForgotPassFlow(true)
  };
  const handlePwdShow = () => {
    setHidePwd({ ...hidePwd, pwd: !hidePwd?.pwd });
  };
  const handleNpwdShow = () => {
    setHidePwd({ ...hidePwd, npwd: !hidePwd.npwd });
  };
  const handleCnpwdShow = () => {
    setHidePwd({ ...hidePwd, cnpwd: !hidePwd.cnpwd });
  };
  const handleAutoLogin = () => {
    try {
      setSuccessPopup(false);
      let obj = {
        countryCodeId: lgnDetails?.cntryCode,
        username: lgnDetails?.mno,
        password: SHA256(lgnDetails?.npwd).toString(),
        role: "POSTER",
      };
      postAPICall(Url?.UserLogin, obj)
        .then((ress) => {
          setLgnDetails({
            cntryCode: "249",
            mno: "",
            pwd: "",
            npwd: "",
            cnpwd: "",
          });
          if (ress?.SUCCESS == true) {
            let token = ress?.token;
            sessionStorage.setItem("token", token);
            getUserDataname();
          } else {
            setPopupMsg(ress?.message);
            setModal(true);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserDataname = () => {
    getAPICall(Url.getuser)
      .then((res) => {
        sessionStorage.setItem("userName", JSON.stringify(res?.DATA?.fullName));
        sessionStorage.setItem("user", JSON.stringify(res?.DATA));
        let companyDetailsAdded = null
        if (res?.DATA?.company.length > 0) {
          companyDetailsAdded = true
        }
        else if (res?.DATA?.company.length == 0) {
          companyDetailsAdded = false
        }
        navigate("/Main/latestJob");
        if (sessionStorage.getItem('jobId')) {
          navigate(`/Main/jobDetails/${sessionStorage.getItem('jobId')}`);
          sessionStorage.removeItem('jobId')
        }
        if (screen == 4) {
          navigate(`/Main/userProfile`, { state: { headerHide: true } });
          if(PortalUserRole == 'ROLE_MANAGER'){
            navigate('/Main/InstituteInfo', { state: { headerHide: true } })
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleKeyPressed = (e, field) => {
    if (field == "login") {
      if (e?.key.toLowerCase() == "enter") {
        handlePosterLogin();
      }
    } else if (field == "mobileNoVerification" && screen == 1) {
      if (e?.key?.toLowerCase() == "enter") {
        handleMobileNoVerification();
      }
    }
  };

  const handleResend = () => {
    try {
      let obj = {
        web: true,
        countryCodeId: lgnDetails?.cntryCode,
        toNumber: lgnDetails?.mno,
        messageType: "OTP_SMS",
        registerd: true,
      };
      postAPICall(Url.SendOtp, obj)
        .then((res) => {
          setOtp2('')
          setForgotPwd(false);
          if (res?.SUCCESS == true) {
            // setScreen(4)
            setMsgAuditId(res?.DATA);
            setsuccessMsg("OTP sent successfully!");
            setSuccessModalIsOpen(true);
          } else {
            setPopupMsg(res?.message);
            setModal(true);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   // Function to handle the incoming message
  //   const receiveMessage = (event) => {
  //     console.log("receiveToken: received token from student", event.origin)
  //     // Verify that the message is coming from the correct origin
  //     // if (event.origin !== 'https://www.shareresume.co/') {
  //     //   console.error('Message from unknown origin:', event.origin);
  //     //   return;
  //     // }

  //     // Log the received message data (token)
  //     console.log('Received token:', event);

  //     // Optionally, store the token in localStorage or cookies
  //     localStorage.setItem('authTokenSample', event.data.token);
  //   };

  //   // Add event listener to listen for the 'message' event
  //   window.addEventListener('message', receiveMessage);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('message', receiveMessage);
  //   };
  // },[]);

  return (
    <>
    {/* iframe 1 with hash */}
      {/* <iframe src="http://localhost:3002/"  width="50%" height="50%" frameborder="0" allowfullscreen></iframe> */}
    {/* iframe 2 without hash */}
    {/* <iframe src="https://www.shareresume.co/ssotesting/" style={{display: 'none'}} width="50%" height="50%" frameborder="0" allowfullscreen></iframe> */}
      <div className="lgnOuterDiv">
        <div className="lgnFormDiv">
          <div className="lgnLogoDiv">
            <img src={logoImageUrl} onClick={() => setScreen(1)} />
          </div>
          <div className="loginH1">
            <h1>Log in to your Account</h1>
          </div>
          {screen != 4 && (
            <div className="loginH1">
              <span
                style={{
                  color:
                    forgotPwd == true && screen == 3
                      ? "#403F3F"
                      : screen == 3
                        ? "#BC0000"
                        : "#403F3F",
                }}
              >
                {forgotPwd == true && screen == 3
                  ? "Reset your password"
                  : screen == 3
                    ? "Your password is not set please set the password"
                    : "Please login to website!"}
              </span>
            </div>
          )}
          {screen != 4 && (
            <div className="RegFieldDiv lgnMtop">
              <label>
                Mobile No
                <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
              </label>
              <div className="RegMnoDiv">
                <select disabled
                  value={lgnDetails?.cntryCode}
                  onChange={(e) => handleLgnInputChange(e, "cntryCode")}
                >
                  {countryCodes.map((item) => {
                    return (
                      <option value={item?.countryCodeId}>
                        +{item?.countryCode}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="number"
                  maxLength={14}
                  value={lgnDetails?.mno}
                  onKeyDown={(e) => handleKeyPressed(e, "mobileNoVerification")}
                  onChange={(e) => handleLgnInputChange(e, "mno")}
                  placeholder="Enter your mobile number"
                />
              </div>
              <small className="RegErrMsg">{errMsg?.mno}</small>
            </div>
          )}
          {screen == 1 ? (
            <div className="RegBtnDiv lgnWidth">
              <button onClick={handleMobileNoVerification}>Next</button>
            </div>
          ) : screen == 2 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  onKeyDown={(e) => handleKeyPressed(e, "login")}
                  type={hidePwd?.pwd == true ? "text" : "password"}
                  value={lgnDetails?.pwd}
                  className="RegInput"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "pwd")}
                />
                <small className="RegErrMsg">{errMsg?.pwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.pwd == "" ? "50%" : "40%" }}
                  onClick={handlePwdShow}
                  src={hidePwd.pwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegResendOtpDiv fieldWidth">
                <span onClick={handleForgotPwd}>Forgot Password?</span>
              </div>
              <div className="RegBtnDiv lgnWidth" style={{ marginTop: "3%" }}>
                <button onClick={handlePosterLogin}>Login</button>
              </div>
            </div>
          ) : screen == 3 ? (
            <div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Enter New Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  type={hidePwd.npwd == false ? "password" : "text"}
                  value={lgnDetails?.npwd}
                  className="RegInput"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "npwd")}
                />
                <small className="RegErrMsg">{errMsg?.npwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.npwd == "" ? "50%" : "40%" }}
                  onClick={handleNpwdShow}
                  src={hidePwd.npwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div
                className="RegFieldDiv lgnMtop"
                style={{ position: "relative" }}
              >
                <label>
                  Confirm Password
                  <span style={{ color: "#BC0000", fontWeight: 400 }}>*</span>
                </label>
                <input
                  maxLength={30}
                  type={hidePwd.cnpwd == false ? "password" : "text"}
                  value={lgnDetails?.cnpwd}
                  className="RegInput"
                  placeholder="Enter your password"
                  onChange={(e) => handleLgnInputChange(e, "cnpwd")}
                />
                <small className="RegErrMsg">{errMsg?.cnpwd}</small>
                <img
                  className="eyeIcon"
                  style={{ top: errMsg?.cnpwd == "" ? "50%" : "40%" }}
                  onClick={handleCnpwdShow}
                  src={hidePwd.cnpwd == true ? eyeIcon : hiddenEyeIcon}
                />
              </div>
              <div className="RegBtnDiv lgnWidth">
                <button onClick={handleOtpSend}>Next</button>
              </div>
            </div>
          ) : screen == 4 ? (
            <div>
              <div className="lgnOtpImgDiv">
                <img src={mobileImg} />
              </div>
              <div className="lgnOtpDivTxt">
                We have sent OTP on your registered mobile number
              </div>
              <div className="lgnOtpDiv">
                <label>
                  Please enter the OTP
                  <small style={{ color: "#BC0000" }}>*</small>
                </label>
                <OtpInput
                  value={otp2}
                  onChange={setOtp2}
                  numInputs={4}
                  renderSeparator={<span style={{ width: "1.5vw" }}></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle="regOTPinputField"
                  inputType="number"
                />
              </div>
              <div className="noOtpTxt">
                Didn't receive a OTP?{" "}
                <span style={{ cursor: "pointer" }} onClick={handleResend}>
                  Resend OTP
                </span>
              </div>
              <div className="setPwdBtnDiv">
                <button className="setPwdBtn" onClick={handleSetPwd}>
                  Set Password
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {PortalUserRole === "ROLE_MANAGER" &&  <div className="LgnNoAcc">
            Don't have an account?&nbsp;
            <span onClick={() => navigate("/register")}>Register</span>
          </div> }
        </div>
        <div className="lgnImgDiv">
          <div className="OverlayNew">
            <div className="RegImgDivTxt LgnImgDivTxt">
              <div className="HiringMore">Make Campus Recruitment</div>
              <div className="humanTxt">Seamlessly</div>
              <p>
              Welcome to CampusPlacement.in – revolutionizing campus recruitment by seamlessly connecting students and college recruiters through innovative processes and technology. Join us in transforming the way talent meets opportunity, making the campus placement process smooth and efficient for everyone involved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          l
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={closeFailModal}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{error}</div>
        <div className="okBtnModal">
          <button onClick={closeFailModal}>
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={SuccessModalIsOpen}
        onRequestClose={closeSuccessModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={closeSuccessModal} className="delAccModalCrossPara">
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{successMsg}</div>
        <div className="okBtnModal">
          <button onClick={closeSuccessModal}>
            OK
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p className="delAccModalCrossPara" onClick={() => setModal(false)}>
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{popupMsg}</div>
        <div className="okBtnModal">
          <button onClick={() => setModal(false)}>OK</button>
        </div>
      </Modal>

      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p
              onClick={() => {
                navigate("/login");
                setSuccessPopup(false);
                setScreen(2);
              }}
              className="delAccModalCrossPara"
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">{popupMsg}</div>
        <div className="okBtnModal">
          <button onClick={handleAutoLogin}>OK</button>
        </div>
      </Modal>
    </>
  );
};

export default Login;
