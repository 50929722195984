import "./StudentRepository.css";
import { Url } from "../../EnvoirnmentSetup/APIs";
import React, { useState, useEffect } from "react";
import CommonTable from "../../Components/table/CommonTable";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import {
  ApiCall, 
  getAPICall,
} from "../../APIMethods/APIMethods";
import delIcon from "../../assets/deleteicon.svg";
import editIcon from "../../assets/editicon.svg";
import closePopup from "../../assets/closepopup.svg";
import latestJobIcon from "../../assets/latestJobIcon.svg";
import {
  field,
  onlyAlphabets, 
  onStudentEmailChange,
  mobileNumberValidations, 
  onlyNumberwithDot,
  onlyNumber,
} from "../../Validation/Validation";
import { useSelectedCardContext } from "../../Context/Context";
import { FaPlus, FaDownload } from "react-icons/fa";
import { PiClockCountdownFill } from "react-icons/pi";
import SampleStudentData from "../../assets/SampleStudentData.xlsx";

const StudentRepository = () => {
  const { setIsLoading, setWarning, setAlert } = useSelectedCardContext();

  const [tableData, setTableData] = useState([]);
  const tableColumns = [
    {
      Header: "S/N",
      accessor: "srno",
    },
    {
      Header: "Student Name",
      accessor: "fullName",
    },
    {
      Header: "Mobile Number",
      accessor: "",
      Cell: ({ row }) => (
       <div>
        <span>{`+${row?.original?.countryCode?.countryCode} ${row?.original?.mobileNumber}` }</span>
       </div>
      ),
    },
    {
      Header: "Email ID",
      accessor: "userName",
    },
    {
      Header: "CGPA",
      accessor: "",
      Cell: ({ row }) => (
        <div>
          <span>
            {row?.original?.score
              ? (row.original.score.includes('%')
                ? row.original.score
                : `${row.original.score}%`)
              : '-'}
          </span>
        </div>
       ),
    },
    {
      Header: "Status",
      accessor: "",
      Cell: ({ row }) => (
        <span
          style={{
            color: row.original?.linkedinProfile ? "#01774A" : "#EA6200",
            fontWeight: 500,
          }}
        >
          {row.original?.linkedinProfile ? "Complete" : "Pending"}
        </span>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div
          className="actionitems"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <img src={viewIcon} alt="" onClick={() => { }} /> */}
          {row.original?.linkedinProfile ? (
            <></>
          ) : (
            <img
              src={editIcon}
              alt=""
              onClick={() => {
                handleOpenStudentModal();
                setStudentData(row.original);
              }}
            />
          )}
          <img
            src={delIcon}
            alt=""
            onClick={() => {
              setDeleteModal(true);
              setDeleteData(row.original);
            }}
          />
        </div>
      ),
    },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: field,
    mobileNumber: field,
    userName: field,
    score : field
  });

  const [UploadingXL, setUPloadingXL] = useState(false);
  const [XLData, setXLData] = useState(null);
  const [CountStatus, setCountStatus] = useState();
  const [DeleteData, setDeleteData] = useState();
  const [DeleteModal, setDeleteModal] = useState(false);
  const [handleSearchData, setHandleSearchData] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [excelErrorData, setExcelErrorData] = useState({
    skippedMailUsers: [],
    skippedMobileUsers: [],
  });

  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = SampleStudentData;
    const instituteData = JSON.parse(
      sessionStorage.getItem("instituteDetails")
    );
    a.download = `${instituteData?.[0]?.instituteName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    getStudentDetails();
  }, []);

  const handleClosePopup = () => {
    setOpenErrorModal(false);
  };
  console.log(formData);
  
  // ---------------------------------------- API Integration -------------------------------------------------
  const getStudentDetails = async () => {
    setIsLoading(true);
    ApiCall(Url?.getStudentList, "GET", true)
      .then((response) => {
        setIsLoading(false);
        if (response.SUCCESS) {
          let filteredData = response?.DATA?.filter((item,i)=>{
            return item.status == "PENDING"
         })
         let filteredDataCompleted = response?.DATA?.filter((item,i)=>{
          return item.status == "ACTIVE"
       })
       
          setTableData(
            filteredData?.map((value, index) => ({
              ...value,
              srno: index + 1,
            }))
          );
          let totalCount = response?.DATA.length;
          let completeCount = filteredDataCompleted.length;
          let pendingCount = filteredData.length;
          setCountStatus({
            totalCount,
            completeCount,
            pendingCount,
          });
        } else {
          console.log(response.message);
        }
      })
      .catch((err) => console.log(err));
  };
console.log('>>>>>>>>>>>', tableData)
  // ------------------------------------- function calls ---------------------------------------------------------
  const handleOpenStudentModal = () => {
    setOpenModal(true);
  };

  const closeStudentModal = () => {
    setOpenModal(false);
    setStudentData();
    setUPloadingXL(false);
  };

  const setStudentData = (data) => {
    setFormData({
      ...data,
      fullName: { ...field, fieldValue: data?.fullName ?? "" },
      mobileNumber: { ...field, fieldValue: data?.mobileNumber ?? "" },
      userName: { ...field, fieldValue: data?.userName ?? "" },
      score: {
        ...field,
        fieldValue: data?.score ? data.score.replace('%', '') : ""
      },
      countryCode: data?.countryCode
    });
  };


  // ------------------  Add or Update student --------------

  const addOrUpdateStudent = () => {
    let instituteName = JSON.parse(sessionStorage.getItem('instituteDetails'))
    if (!formData.fullName?.fieldValue?.trim() || !formData.fullName?.isValidField) {
      setWarning("Student Name is required.");
    } else if (!formData.mobileNumber?.fieldValue?.trim()) {
      setWarning("Student mobile number is required.");
    } else if (
      formData.mobileNumber?.fieldValue?.trim().length < 4 ||
      formData.mobileNumber?.fieldValue?.trim().length > 10
    ) {
      setWarning("Student mobile number must be between 4 and 10 characters.");
    } else if (formData?.userName?.isValidField === false) {
      setWarning("A valid Student email is required.");
    } else if (formData?.mobileNumber?.isValidField === false) {
      setWarning("A valid Mobile Number is required.");
    } else if(!formData.userName?.fieldValue){
      setWarning("The email address should not be empty.");
    }else if(!instituteName?.[0]?.instituteName){
      setWarning("Institute Is required for this action.");
    }else {
      const body = {
        // ...formData,
        web: true,
        userId: formData.userId,
        fullName: formData.fullName?.fieldValue?.trim(),
        mobileNumber: formData.mobileNumber?.fieldValue?.trim(),
        userName: formData.userName?.fieldValue?.trim(),
        score : formData?.score?.fieldValue?.trim() + "%",
        countryCode: {
          countryCodeId: "249",
        },
        roles: [
          {
            name: "ROLE_APPLICANT",
          },
        ],
      };
    
      setIsLoading(true);
      ApiCall(
        Url.managerUserAdd,
        formData?.userId ? "PUT" : "POST",
        true,
        "",
        body
      )
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            closeStudentModal();
            getStudentDetails();
            setAlert(
              `Student ${formData?.userId ? "updated" : "added"} successfully!`
            );
          } else {
            setWarning(res.message);
          }
        })
        .catch((err) => console.log(err));
    }
    




  };

  // --------------------------------------------------- 

  const UnladeXLDoc = () => {
    if (!XLData?.name) {
      setWarning("Select XL to Add Students");
      return;
    }
    const body = new FormData();
    body.append("user-file", XLData);

    setIsLoading(true);
    ApiCall(Url.uploadXLStudent, "POST", true, "", body, true)
      .then((res) => {
        setIsLoading(false);
        if (res.SUCCESS) {
          closeStudentModal();
          getStudentDetails();

          setXLData(null);

          if (res?.skippedMailUsers?.length > 0 || res?.skippedMobileUsers?.length > 0) {
            setOpenErrorModal(true);
            setExcelErrorData({
              ...res
            });
          }else{
            setAlert("Students Added Successfully!");
            setXLData(null);
          }
        } else {
          setWarning(res.message);
          setXLData(null);
        }
      })
      .catch((err) => console.log(err));
  };

  const DeleteStudent = () => {
    if (DeleteData) {
      const userBody = {
        messageAuditId: 0,
        messageOTP: 1111,
        mobileNumber: DeleteData?.mobileNumber,
        countryCode: DeleteData?.countryCode,
      };
      setIsLoading(true);
      ApiCall(Url.hardDeleteUser, "DELETE", true, "", userBody)
        .then((res) => {
          setIsLoading(false);
          if (res.SUCCESS) {
            getStudentDetails();
            setAlert("Student deleted successfully!");

            setDeleteModal(false);
            setDeleteData(null);
          } else {
            setWarning(res.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  // ------------------------------------- function calls ---------------------------------------------------------
  const handleSendReminder = () => {
    if (tableData.length > 0) {
      setIsLoading(true);
      getAPICall(Url.sendReminderForAll).then((res) => {
        setIsLoading(false);
        if (res.SUCCESS) {
          setAlert("The reminder was successfully sent to everyone!");
        } else {
          setWarning("Somthing Went Wrong.");
        }
      });
    } else {
      setWarning("Student details not available.");
    }
  };

  return (
    <div className="mainStudentRepositoryContainer">
      <div className="StudentHeaderText">
        <div className="imageAndText">
          <img src={latestJobIcon} alt="" />
          <span>Pending Students</span>
        </div>
        <div className="studentCountContainerDiv">
          <div className="studentCountDiv" style={{ color: "#000" }}>
            <span style={{ borderColor: "#000" }}>
              {CountStatus?.totalCount
                ? CountStatus?.totalCount < 10
                  ? "0" + CountStatus?.totalCount
                  : CountStatus?.totalCount
                : "00"}
            </span>
            No. of Students
          </div>
          <div className="studentCountDiv" style={{ color: "#01774A" }}>
            <span style={{ borderColor: "#01774A" }}>
              {CountStatus?.completeCount
                ? CountStatus?.completeCount < 10
                  ? "0" + CountStatus?.completeCount
                  : CountStatus?.completeCount
                : "00"}
            </span>
            Students Registerd
          </div>
          <div className="studentCountDiv" style={{ color: "#EA6200" }}>
            <span style={{ borderColor: "#EA6200" }}>
              {CountStatus?.pendingCount
                ? CountStatus?.pendingCount < 10
                  ? "0" + CountStatus?.pendingCount
                  : CountStatus?.pendingCount
                : "00"}
            </span>
            Pending Students
          </div>
        </div>
      </div>
      <div className="tableHeader">
        {/* <div className="searchbar">
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setHandleSearchData(e.target.value)}
          />
        </div> */}
        {/* <div className="sortdata">
          <button type="button">
            Sort By : <span>A to Z</span>
            <span></span>
          </button>
        </div> */}
      </div>
      <div className="studentRepositorytableDiv">
        <CommonTable
          data={tableData}
          columns={tableColumns}
          handleSearchData={handleSearchData}
          buttons={
            <>
              <button
                className="addStudentButton"
                onClick={handleOpenStudentModal}
              >
                <FaPlus />
                Add Student
              </button>
              <button
                className="studentReminderButton"
                onClick={() => {
                  handleSendReminder();
                }}
              >
                <PiClockCountdownFill size={"1.5rem"} />
                Reminder Send to Pending's
              </button>
            </>
          }
        />
      </div>

      <ComponentAndScreenConstant.CommonModal
        isOpen={openModal}
        // onRequestClose={closeStudentModal}
        className="addStudentModal"
      >
        <img
          src={closePopup}
          alt="closeicon"
          className="closeIcon"
          onClick={closeStudentModal}
        />
        <h2>{!formData?.userId ? "Add Student" : "Update Student"}</h2>
        {!formData?.userId && (
          <div className="toggleContainer">
            <button
              className={`toggleButton ${UploadingXL ? "" : "activeToggle"}`}
              onClick={() => {
                setUPloadingXL(false);
              }}
            >
              Single Student
            </button>
            <button
              className={`toggleButton ${!UploadingXL ? "" : "activeToggle"}`}
              onClick={() => {
                setUPloadingXL(true);
              }}
            >
              Multiple Student
            </button>
          </div>
        )}
        {UploadingXL ? (
          <div className="filePickerDiv">
            <div className="fileInputDiv" style={{ height: "125px" }}>
              <button
                onClick={() => {
                  downloadFile();
                }}
                className="downloadSample"
              >
                <FaDownload /> Download Sample Excel
              </button>
              <input
                type="file"
                // accept='image/*'
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xl, .xls, .xlsx"
                onChange={(e) => {
                  console.log(e.target.files[0]);
                  setXLData(e.target.files[0]);
                }}
              />
              <p>{XLData?.name ?? "Select Student Excel Data"}</p>
            </div>
          </div>
        ) : (
          <div className="instituteFormDiv gap-10">
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.InputText
                labelTxt="Student Name"
                inputType="text"
                inputId="fullName"
                inputValue={formData?.fullName?.fieldValue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    fullName: onlyAlphabets("Full Name", e.target.value),
                  })
                }
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.fullName?.errorField}
                placeholderTxt="Enter Student Name"
                isRequired={true}
                maxLength={100}
              />
              <ComponentAndScreenConstant.MobileInput
                labelTxt="Mobile No"
                inputType="text"
                inputId="mobile"
                inputClass="fullNameInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={formData?.mobileNumber?.fieldValue}
                selectedValue={formData?.countryCode?.countryCodeId}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mobileNumber: mobileNumberValidations(
                      "Mobile Number",
                      e.target.value,
                      10
                    ),
                  });
                }}
                onSelect={(e) => {
                  setFormData({
                    ...formData,
                    countryCode: { countryCodeId: e.target.value },
                  });
                }}
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.mobileNumber?.errorField}
                placeholderTxt="Enter Mobile Number"
                isRequired={true}
                maxLength={10}
              />
            </div>
            <div className="formFlexRowDiv">
              <ComponentAndScreenConstant.InputText
                labelTxt="Email ID"
                inputType="text"
                inputId="email"
                inputClass="fullNameInput"
                inputValue={formData?.userName?.fieldValue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    userName: onStudentEmailChange(
                      "Email Address",
                      e.target.value
                    ),
                  })
                }
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.userName?.errorField}
                placeholderTxt="Enter Email ID"
                isRequired={true}
                maxLength={50}
              />
              <ComponentAndScreenConstant.InputText
                labelTxt="CGPA / Percentage"
                inputType="text"
                inputId="email"
                inputClass="fullNameInput"
                inputValue={formData?.score?.fieldValue}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    score: onlyNumberwithDot(
                      "CGPA",
                      e.target.value
                    ),
                  })
                }
                inputWrapperStyle="fullNameWrapper"
                errMsg={formData?.score?.errorField}
                placeholderTxt="Enter score"
                isRequired={true}
                maxLength={5}
              />
            </div>
          </div>
        )}
        <div className="buttonDiv">
          <button onClick={closeStudentModal}>Cancel</button>
          <button
            style={{ backgroundColor: "#01774A", color: "#fff" }}
            onClick={UploadingXL ? UnladeXLDoc : addOrUpdateStudent}
          >
            {!formData?.userId ? "Add" : "Update"}
          </button>
        </div>
      </ComponentAndScreenConstant.CommonModal>
      <ComponentAndScreenConstant.AcceptRejectModal
        isOpen={DeleteModal}
        message={"Do you want to delete this student?"}
        onRequestClose={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onCancel={() => {
          setDeleteModal(false);
          setDeleteData(null);
        }}
        onSelect={() => {
          DeleteStudent();
        }}
      />

      <ComponentAndScreenConstant.ErrorModal
        isOpen={openErrorModal}
        handleClosePopup={handleClosePopup}
        excelErrorData={excelErrorData}
      />
    </div>
  );
};

export default StudentRepository;
